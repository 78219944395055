import { lazy } from 'react'
import MainLayout from 'src/layout/MainLayout'
import Loadable from 'src/ui-component/Loadable'
import AuthGuard from 'src/utils/route-guard/AuthGuard'
import { Outlet } from 'react-router-dom'
import type { RouteObject } from 'react-router-dom'

const PagePreview = Loadable(lazy(() => import('src/views/page/PagePreview')))
const FormPreview = Loadable(lazy(() => import('src/views/form/FormPreview')))
const TemplatePreview = Loadable(lazy(() => import('src/views/template/TemplatePreview')))
const SpaceInvite = Loadable(lazy(() => import('src/views/space/Invite')))

const FormList = Loadable(lazy(() => import('src/views/form/FormList')))
const CreateForm = Loadable(lazy(() => import('src/views/form/CreateUpdateForm')))
const FormRecord = Loadable(lazy(() => import('src/views/form/FormRecord')))

const PageList = Loadable(lazy(() => import('src/views/page/PageList')))
const CreatePageNew = Loadable(lazy(() => import('src/views/page/CreateUpdatePage')))
// const CreatePage = Loadable(lazy(() => import('src/views/page/CreateUpdatePage_old')))
const CreatePage = Loadable(lazy(() => import('src/views/page/CreateUpdatePage')))
const CreateUpdatePartner = Loadable(lazy(() => import('src/views/partners/CreateUpdate')))
const Spaces = Loadable(lazy(() => import('src/views/superadmin/spaces/List')))
const SpaceTemplatesList = Loadable(lazy(() => import('src/views/superadmin/space-templates/List')))
const Blocks = Loadable(lazy(() => import('src/views/superadmin/blocks/List')))
const UpdateBlock = Loadable(lazy(() => import('src/views/superadmin/blocks/Update')))
const ExclusiveBlocks = Loadable(lazy(() => import('src/views/exclusive-blocks/List')))
const UpdateExclusiveBlocks = Loadable(lazy(() => import('src/views/exclusive-blocks/Update')))
const CustomerList = Loadable(lazy(() => import('src/views/superadmin/customers/CustomersList')))
const EditCustomer = Loadable(lazy(() => import('src/views/superadmin/customers/EditCustomer')))
const BlockList = Loadable(lazy(() => import('src/views/superadmin/blocks-data/List')))
const Edit = Loadable(lazy(() => import('src/views/superadmin/blocks-data/Edit')))

const TemplateList = Loadable(lazy(() => import('src/views/template/TemplateList')))
const CreateTemplate = Loadable(lazy(() => import('src/views/template/CreateUpdateTemplate')))

const AnalyticsPage = Loadable(lazy(() => import('src/views/analytics')))
const GoalConversions = Loadable(lazy(() => import('src/views/analytics/GoalConversions')))
const AssetsPage = Loadable(lazy(() => import('src/views/assets')))
const DashboardPage = Loadable(lazy(() => import('src/views/dashboard')))
const ProductList = Loadable(lazy(() => import('src/views/product/ProductList')))
const CreateProduct = Loadable(lazy(() => import('src/views/product/CreateUpdateProduct')))
const CreateDataset = Loadable(lazy(() => import('src/views/dataset/CreateUpdate')))
const UpdateDataset = Loadable(lazy(() => import('src/views/dataset/CreateUpdate')))
const DatasetList = Loadable(lazy(() => import('src/views/dataset/List')))
const Feed = Loadable(lazy(() => import('src/views/dataset/Feed')))
const SettingsPage = Loadable(lazy(() => import('src/views/settings/GeneralTab')))
const Styling = Loadable(lazy(() => import('src/views/settings/Styling')))
const TagsPage = Loadable(lazy(() => import('src/views/tag/List')))
const CreateTag = Loadable(lazy(() => import('src/views/tag/CreateUpdate')))
const Members = Loadable(lazy(() => import('src/views/members/List')))
const PartnersList = Loadable(lazy(() => import('src/views/partners/List')))
const EditMember = Loadable(lazy(() => import('src/views/members/CreateUpdate')))
// const HeaderList = Loadable(lazy(() => import('src/views/navigation/header/List')))
const CreateUpdateHeader = Loadable(lazy(() => import('src/views/navigation/header/CreateUpdateHeader')))
const NavigationList = Loadable(lazy(() => import('src/views/navigation/List')))
const CreateUpdateFooter = Loadable(lazy(() => import('src/views/navigation/footer/CreateUpdateFooter')))
// const SidebarList = Loadable(lazy(() => import('src/views/navigation/sidebar/List')))
const CreateUpdateSidebar = Loadable(lazy(() => import('src/views/navigation/sidebar/CreateUpdateSidebar')))
const SpaceList = Loadable(lazy(() => import('src/views/space/List')))
const UserList = Loadable(lazy(() => import('src/views/users/userList')))
const EditUser = Loadable(lazy(() => import('src/views/users/EditUser')))
const NewsletterList = Loadable(lazy(() => import('src/views/newsletter/List')))
const CommunicationList = Loadable(lazy(() => import('src/views/communication/List')))
const Organization = Loadable(lazy(() => import('src/views/organization')))
// const Index = Loadable(lazy(() => import('src/views/index/Home')))
// const AuthLogin = Loadable(lazy(() => import('src/views/authentication/authentication3/Login3')))
// const AuthRegisterInfo = Loadable(lazy(() => import('src/views/authentication/authentication3/RegisterInfo')))
const CreateNewsletter = Loadable(lazy(() => import('src/views/newsletter/CreateUpdate')))
const CreateCommunication = Loadable(lazy(() => import('src/views/communication/CreateUpdate')))
const Recipients = Loadable(lazy(() => import('src/views/newsletter/Recipients')))
const Triggers = Loadable(lazy(() => import('src/views/communication/Triggers')))

const SignUp = Loadable(lazy(() => import('src/views/authentication/authentication3/SignUp')))
const SignIn = Loadable(lazy(() => import('src/views/authentication/authentication3/SignIn')))
const NewsletterSetting = Loadable(lazy(() => import('src/views/settings/NewsletterSettings')))
const MemberSetting = Loadable(lazy(() => import('src/views/settings/AddRole')))
const Seo = Loadable(lazy(() => import('src/views/settings/SEOTab')))
const Integration = Loadable(lazy(() => import('src/views/settings/Payment')))

const LayoutAdminRoutes: RouteObject[] = [
    {
        path: '/dashboard',
        element: <DashboardPage />
    },
    {
        path: '/organization',
        element: <Organization />
    },
    {
        path: '/space/list',
        element: <SpaceList />
    },
    {
        path: '/user/list',
        element: <UserList />
    },
    {
        path: '/user/:action/:id',
        element: <EditUser />
    },
    {
        path: '/superadmin/blocks',
        element: <Blocks />
    },
    {
        path: '/superadmin/blocks/edit/:id',
        element: <UpdateBlock />
    },
    {
        path: '/superadmin/spaces/list',
        element: <Spaces />
    },
    {
        path: '/exclusive-blocks/edit/:id',
        element: <UpdateExclusiveBlocks />
    },
    {
        path: '/exclusive-blocks',
        element: <ExclusiveBlocks />
    },
    {
        path: '/superadmin/space-template/list',
        element: <SpaceTemplatesList />
    },
    {
        path: '/superadmin/customer/list',
        element: <CustomerList />
    },
    {
        path: '/superadmin/customer/edit/:id',
        element: <EditCustomer />
    },
    {
        path: '/superadmin/blocks-data/list',
        element: <BlockList />
    }
]

const LayoutSpaceRoutes: RouteObject[] = [
    {
        path: '/form/list',
        element: <FormList />
    },
    {
        path: '/form/record/:id',
        element: <FormRecord />
    },
    {
        path: '/page/list',
        element: <PageList />
    },
    {
        path: '/partner/list',
        element: <PartnersList />
    },
    {
        path: '/newsletter/recipients/:id',
        element: <Recipients />
    },
    {
        path: '/communication/triggers/:id',
        element: <Triggers />
    },
    {
        path: '/config/template/list',
        element: <TemplateList />
    },
    {
        path: '/listing/list',
        element: <ProductList />
    },
    {
        path: '/clicks',
        element: <GoalConversions />
    },
    {
        path: '/analytics',
        element: <AnalyticsPage />
    },
    {
        path: '/dataset/list',
        element: <DatasetList />
    },
    {
        path: '/config/tag/list',
        element: <TagsPage />
    },
    {
        path: '/member/list',
        element: <Members />
    },
    {
        path: '/config/assets',
        element: <AssetsPage />
    },
    {
        path: '/config/styling',
        element: <Styling />
    },
    {
        path: '/config/navigation/list',
        element: <NavigationList />
    },
    {
        path: '/newsletter/list',
        element: <NewsletterList />
    },
    {
        path: '/config/communication/list',
        element: <CommunicationList />
    },
    {
        path: '/config/general',
        element: <SettingsPage />
    },
    {
        path: '/config/newsletter',
        element: <NewsletterSetting />
    },
    {
        path: '/config/seo',
        element: <Seo />
    },
    {
        path: '/config/membership',
        element: <MemberSetting />
    },
    {
        path: '/config/integrations',
        element: <Integration />
    }
]

const LayoutCommonRoutes: RouteObject[] = []

const NoLayoutSpaceRoutes: RouteObject[] = [
    {
        path: '/page/new',
        element: <CreatePage />
    },
    {
        path: '/page/edit/:id',
        element: <CreatePage />
    },
    {
        path: '/form/edit/:id',
        element: <CreateForm />
    },
    {
        path: '/partner/new',
        element: <CreateUpdatePartner />
    },
    {
        path: '/partner/edit/:id',
        element: <CreateUpdatePartner />
    },
    {
        path: '/page/edit/new/:id',
        element: <CreatePageNew />
    },
    {
        path: '/communication/edit/:id',
        element: <CreateCommunication />
    },
    {
        path: '/page/preview/:id',
        element: <PagePreview />
    },
    {
        path: '/newsletter/edit/:id',
        element: <CreateNewsletter />
    },
    {
        path: '/form/preview/:id',
        element: <FormPreview />
    },
    {
        path: '/template/preview/:id',
        element: <TemplatePreview />
    },
    // {
    //     path: '/navigation/header/preview/:id',
    //     element: <HeaderPreview />
    // },
    {
        path: '/navigation/header/new',
        element: <CreateUpdateHeader />
    },
    {
        path: '/navigation/header/edit/:id',
        element: <CreateUpdateHeader />
    },
    {
        path: '/navigation/sidebar/edit/:id',
        element: <CreateUpdateSidebar />
    },
    {
        path: '/navigation/footer/edit/:id',
        element: <CreateUpdateFooter />
    },
    {
        path: '/navigation/footer/new',
        element: <CreateUpdateFooter />
    },
    {
        path: '/communication/new',
        element: <CreateCommunication />
    },
    {
        path: '/navigation/sidebar/new',
        element: <CreateUpdateSidebar />
    },
    {
        path: '/superadmin/blocks-data/edit/:id',
        element: <Edit />
    }
]

const NoLayoutAdminRoutes: RouteObject[] = [
    {
        path: '/page/new/templateId/:templateId',
        element: <CreatePage />
    },
    {
        path: '/dataset/edit/:id',
        element: <UpdateDataset />
    },
    {
        path: '/dataset/edit/feed/:id',
        element: <Feed />
    },
    {
        path: '/listing/edit/:id',
        element: <CreateProduct />
    },
    

    {
        path: '/member/edit/:id',
        element: <EditMember />
    },
    {
        path: '/tag/edit/:id',
        element: <CreateTag />
    },
    {
        path: '/template/edit/:id',
        element: <CreateTemplate />
    },
    {
        path: '/form/new',
        element: <CreateForm />
    },
    {
        path: '/newsletter/new',
        element: <CreateNewsletter />
    },
    {
        path: '/template/new',
        element: <CreateTemplate />
    },
    {
        path: '/listing/new',
        element: <CreateProduct />
    },
    {
        path: '/dataset/new',
        element: <CreateDataset />
    },
    {
        path: '/tag/new',
        element: <CreateTag />
    },

]

const NoLayoutCommonRoutes: RouteObject[] = [
    {
        path: '/space/invite/:id',
        element: <SpaceInvite />
    },
]

const LayoutMainRoutes: RouteObject[] = [
    {
        element: (
            <AuthGuard>
                <MainLayout />
            </AuthGuard>
        ),
        children: [...LayoutAdminRoutes, ...LayoutSpaceRoutes, ...LayoutCommonRoutes]
    }
]

const NoLayoutMainRoutes: RouteObject[] = [
    {
        element: (
            <AuthGuard>
                <Outlet />
            </AuthGuard>
        ),
        children: [...NoLayoutAdminRoutes, ...NoLayoutSpaceRoutes, ...NoLayoutCommonRoutes]
    }
]

const AdminRoutes = NoLayoutAdminRoutes.concat(LayoutAdminRoutes)
const SpaceRoutes = NoLayoutSpaceRoutes.concat(LayoutSpaceRoutes)
const CommonRoutes = NoLayoutCommonRoutes.concat(LayoutCommonRoutes)
const LoginRoutes: RouteObject[] = [
    {
        path: '/login',
        element: <SignIn />
    },
    {
        path: '/create-account',
        element: <SignUp />
    }
]

const MainRoutes: RouteObject = {
    path: '/',
    children: [...NoLayoutMainRoutes, ...LayoutMainRoutes]
}

export { AdminRoutes, SpaceRoutes, CommonRoutes, LoginRoutes }
export default MainRoutes
